<template>
  <div>
    <vue-headful :title="title" />
    <v-slide-x-transition appear>
      <v-container fill-height white--text class="top">
        <v-row>
          <v-col cols="12" lg="12" align="center" justify="center">
            <v-form @submit.prevent="buscar">
              <v-row>
                <v-col cols="12" lg="10" md="8">
                  <v-text-field dark label="Escriba el dominio que desea buscar" outlined clearable filled
                    v-model="dominio"></v-text-field>
                </v-col>
                <v-col class="d-flex" cols="12" lg="2" md="12">
                  <v-btn class="mr-2 buscar" color="#003791" elevation="9" large :disabled="loading"
                    v-on:click="buscar">
                    Buscar
                    <v-progress-circular v-if="loading" indeterminate color="primary"></v-progress-circular>
                    <span v-if="!loading"> </span>
                  </v-btn>
                </v-col>
              </v-row>
            </v-form>
          </v-col>
        </v-row>
      </v-container>
    </v-slide-x-transition>
    <v-container>
      <template v-if="notFound === false">
        <v-container>
          <v-row>
            <v-col cols="12" lg="12">
              <h1 class="display-1 mt-5 text-center title-blue" v-for="(item, i) in items" :key="i">{{ item.text }}</h1>
            </v-col>
            <v-col cols="12" lg="12" v-model="items" v-for="(item, i) in items" :key="('A' + i)">
              <h3 class="negrita">
                <v-icon left>mdi-domain</v-icon>{{ item.subtitle }}
              </h3>
            </v-col>
            <v-divider></v-divider>
          </v-row>

          <!-- Tabla de datos de empresa -->
          <v-simple-table class="custom-table">
            <template v-slot:default>
              <thead>
                <tr>
                  <th class="custom-header">
                    <v-icon left class="white-icon">mdi-domain</v-icon> Empresa
                  </th>
                  <th class="custom-header">
                    <v-icon left class="white-icon">mdi-calendar-clock</v-icon> Fecha de Expiración de Dominio
                  </th>
                  <th class="custom-header">
                    <v-icon left class="white-icon">mdi-map-marker</v-icon> Dirección de Empresa
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>{{ data.datos.cliente }}</td>
                  <td>{{ data.datos.fechaExpiracion }}</td>
                  <td>{{ data.datos.direccion }}</td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>

          <!-- Información de contacto -->
          <v-row>
            <v-col cols="12" lg="12" v-model="items" v-for="(item, i) in items" :key="('A' + i)">
              <h3 class="negrita mt-8">
                <v-icon left>mdi-account</v-icon>{{ item.subtitle2}}
              </h3>
            </v-col>
          </v-row>

          <!-- Tabla de contactos -->
          <v-simple-table class="custom-table">
            <template v-slot:default>
              <thead>
                <tr>
                  <th class="text-center custom-header">
                    <v-icon left class="white-icon">mdi-account-circle</v-icon> Nombre
                  </th>
                  <th class="text-center custom-header">
                    <v-icon left class="white-icon">mdi-email</v-icon> Correo Electrónico
                  </th>
                  <th class="text-center custom-header">
                    <v-icon left class="white-icon">mdi-phone</v-icon> Contacto
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>{{ data.contactos.nombre }}</td>
                  <td class="text-center">{{ data.contactos.correoElectronico[0].value }}</td>
                  <td class="text-center">{{ data.contactos.telefono }}</td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-container>
      </template>
      <template v-else>
        <div class="row">
          <v-col cols="12" lg="12">
            <h1 class="display-1 mt-5 text-center" v-for="(item, i) in items" :key="i">{{notFoundMessage.mensaje}}</h1>
          </v-col>
        </div>
      </template>
    </v-container>
  </div>
</template>

<script>
import vueHeadful from 'vue-headful';
export default {
  components: {
    vueHeadful
  },
  data: () => ({
    title: 'WhoIs',
    items: [
      {
        'text': 'Resultado de la búsqueda WhoIs',
        'subtitle': 'Datos de Empresa',
        'subtitle2': 'Información de Contacto'
      }
    ],
    data: [],
    loading: false,
    dominio: '',
    items2: ['.ni', '.com', '.edu', '.net'],
    notFound: true,
    notFoundMsg: '',
    searched: false
  }),
  computed: {
    notFoundMessage() {
      return this.notFoundMsg;
    }
  },
  methods: {
    buscar: async function () {
      this.loading = true;
      await this.$http.get(this.$keys('WHO_IS') + '?dominio=' + this.dominio)
        .then(resp => {
          if (resp.status == 200) {
            let fechaFin = resp.data.datos.fechaExpiracion.split("T", 3);
            resp.data.datos.fechaExpiracion = fechaFin[0];
            this.data = resp.data;
            this.notFound = false;
            this.loading = false;
          }
        }).catch(err => {
          this.notFound = true;
          this.notFoundMsg = JSON.parse(err.response.data.mensaje);
          this.loading = false;
        });
    }
  }
}
</script>

<style>
.top {
  margin-top: -160px !important;
}

@media (max-width: 725px) {
  .top {
    margin-top: -250px !important;
  }

  .buscar {
    margin-top: -20px !important;
    text-align: center !important;
  }
}

.negrita {
  font-weight: 600 !important;
  line-height: 43px !important;
  font-size: 24px;
  color: #003791;
  /* Cambiado a azul corporativo */
  border-bottom: 2px solid #003791;
  /* Borde decorativo */
  padding-bottom: 8px;
  margin-bottom: 16px;
}

.custom-table {
  border: 1px solid #003791;
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 4px 6px rgba(0, 55, 145, 0.1);
}

.custom-table thead tr {
  background-color: #003791 !important;
}

.custom-table th {
  color: white !important;
  font-weight: 600;
  letter-spacing: 0.5px;
  text-align: center !important;
  padding: 16px !important;
}

.custom-table td {
  text-align: center !important;
  padding: 14px !important;
  border-bottom: 1px solid #e0e0e0;
}

.custom-table tbody tr:nth-child(even) {
  background-color: #f5f8ff;
  /* Azul muy claro para filas alternas */
}

.custom-table tbody tr:hover {
  background-color: #e3efff;
  /* Efecto hover suave */
  transition: background-color 0.3s ease;
}

.v-divider {
  border-color: #003791 !important;
  margin: 24px 0;
}

.theme--light.v-label {
  color: #fff !important;
}

.v-btn:not(.v-btn--round).v-size--large {
  height: 55px;
  min-width: 78px;
  padding: 0 19.5555555556px;
  margin-left: 5px;
  color: #fff;
}

.theme--light.v-input input, .theme--light.v-input textarea {
  color: #fff !important;
}

.theme--light.v-select .v-select__selection--comma {
  color: #fff !important;
}

.mdi-menu-down::before {
  content: "\F035D";
  color: #fff;
}

.v-list-item__title {
  color: #000 !important;
}

.decoracion {
  text-decoration: none !important;
}

.title-blue {
  color: #003791 !important;
  font-size: 1.8rem;
  font-weight: bold;
}

.custom-header {
  color: white !important;
  font-size: 1rem !important;
  font-weight: bold;
}
.title-blue {
  color: #003791 !important; /* Azul corporativo */
}
.white-icon {
  color: white !important;
}

</style>
